<template>
    <h1>U3 Filter</h1>
    <br>
    <CContainer>
        <CRow>
            <CCol :md="3">
                <input type="date" class="form-control" v-model="u3Date" />
            </CCol>
        </CRow>
        <CRow><br></CRow>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol :md="12">
                        <CRow style="border-bottom: 4px black solid;padding: 5px;">
                            <CCol :md="1">
                                Eventid
                            </CCol>
                            <CCol :md="4">
                                Match
                            </CCol>
                            <CCol :md="2">
                                Lega
                            </CCol>
                            <CCol :md="2">
                                Ora
                            </CCol>
                            <CCol :md="2">
                                Team
                            </CCol>
                            <CCol :md="1">
                                Azione
                            </CCol>
                        </CRow>
                        <CRow v-for="(game, indG) in u3Games" style="border-bottom: 1px black solid;padding: 5px;">
                            <CCol :md="1">
                                {{ game.next_game.id }}
                            </CCol>
                            <CCol :md="4">
                                {{ game.next_game.home.name + " vs " + game.next_game.away.name}}
                            </CCol>
                            <CCol :md="2">
                                {{ game.league_name }}
                            </CCol>
                            <CCol :md="2">
                                {{ game.next_date }}
                            </CCol>
                            <CCol :md="2">
                                {{ game.team_name }}
                            </CCol>
                            <CCol :md="1">
                                <CButton href="#" @click="clickHandle(game)" color="danger" shape="rounded-pill">
                                    Elimina
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

    </CContainer>
</template>

<script>
import { ref, watch } from 'vue-demi';
import { useU3Store } from '../../store/u3_store';

export default {
    name:'FXfilter',
    setup() {
        const u3Date = ref(new Date());
        const store = useU3Store();
        const u3Games = ref([]);
        const removedGame = ref(false);

        watch(u3Date, (val) => {
            const dateToValidate = val.split('-');
            const dateToSend = dateToValidate[2]+'-'+dateToValidate[1]+'-'+dateToValidate[0];
            store.setDate(dateToSend);
            store.getGames.then(data => {
                u3Games.value = data.data;
            });
        })

        watch(removedGame, (val) => {
            if (val) {
                store.getGames.then(data => {
                    u3Games.value = data.data;
                });
                removedGame.value = false;
            }
        })

        const clickHandle = (game) => {
            store.removeGame(game.next_game.id).then(response => {
                if (response.data == 200) {
                    const index = u3Games.value.indexOf(game);
                    if (index != -1) {
                        u3Games.value.splice(index, 1);
                    }
                }
            });
        }

        return {
            u3Date,
            u3Games,
            clickHandle
        }
    }
}
</script>

<style>
</style>