import { defineStore } from 'pinia'
import axios from 'axios';

export const useU3Store = defineStore({
    id: "u3_filter",
    state: () => ({
        date: "",
    }),
    getters: {
        getGames: async (state) => {
            const data = await axios.get(`https://api.soccerstandings.online/filter/U3/${state.date}`);
            return data;
        },

    },
    actions: {
        setDate(date) {
            this.date = date;
        },
        async removeGame(eventid) {
            const response = await axios.get(`https://api.soccerstandings.online/remove_game/U3/${eventid}`);
            return response;
        }
    },
});
